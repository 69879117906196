* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-family: Arial, sans-serif;
  background-color: #1e1e1e;
  color: #ffffff;
}

.container {
  text-align: center;
  background-color: #2e2e2e;
  padding: 50px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

h1 {
  color: #ffffff;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

p {
  font-size: 1.2rem;
  color: #cccccc;
  margin-bottom: 30px;
}

.linkedin-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  color: #ffffff;
  background-color: #0077B5;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.linkedin-button:hover {
  background-color: #005983;
}
